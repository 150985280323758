import $ from 'jquery';

$('body.ads-edit').on('change', 'input[name="file"]', function() {
	if(typeof FileReader === 'undefined') {
		return;
	}

	var reader = new FileReader();
	var $self = $(this);

    reader.onload = function(e) {
		var $img = $('<img src="' + e.target.result + '">');
		$('.file-thumbnail').empty();
        $('.file-thumbnail').append($img);
    };

	var input = $self.get(0);
	if(input.files && input.files[0]) {
		reader.readAsDataURL(input.files[0]);
	}
});